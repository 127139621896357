import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";

import GetStarted from "components/cta/GetStartedLight.js";
import FAQ from "components/faqs/SingleCol.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter";
import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import i18n from "helpers/i18n";
import SimpleSubscribeNewsletter from "components/forms/SimpleSubscribeNewsletter";
import Experience from "components/experience";

const HighlightedText = tw.span`text-primary-500`;

export default () => {
  return (
    <AnimationRevealPage disabled={process.env.NODE_ENV !== "production"}>
      <Hero />
      <FeatureStats />
      <Features
        heading={
          <>
            {i18n.t("Nội tại")} <HighlightedText>Re:Marketing</HighlightedText>
          </>
        }
      />
      <MainFeature
        heading={
          <>
            {i18n.t("Tối ưu hóa tệp khách hàng")}{" "}
            <HighlightedText>Re:Marketing</HighlightedText>
          </>
        }
      />
      <Testimonial
        heading={
          <>
            {i18n.t("Đối tác")}{" "}
            <HighlightedText>{i18n.t("Hài lòng")}</HighlightedText>
          </>
        }
      />
      <Pricing
        heading={
          <>
            {i18n.t("Đa dạng")}{" "}
            <HighlightedText>{i18n.t("Gói cước")}</HighlightedText>
          </>
        }
      />
      <FAQ
        heading={
          <>
            {i18n.t("Bạn có")}{" "}
            <HighlightedText>{i18n.t("Câu hỏi ?")}</HighlightedText>
          </>
        }
      />
      {/* <Blog
        subheading="Blog"
        heading={<>We love <HighlightedText>Writing</HighlightedText></>}
      /> */}

      <GetStarted />
      <Experience
        heading={
          <>
            {i18n.t("Trải nghiệm")}{" "}
            <HighlightedText>{i18n.t("Sử dụng")}</HighlightedText>
          </>
        }
      />
      <SimpleSubscribeNewsletter />
      <MiniCenteredFooter />
    </AnimationRevealPage>
  );
};
