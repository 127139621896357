import { ReactComponent as Line } from "images/5line.svg";
import { ReactComponent as ArrowExperience } from "images/arrow-experience.svg";
import bag from "images/bag.svg";
import { ReactComponent as Experience } from "images/experience.svg";
import gift from "images/gift.svg";
import money from "images/money.svg";
import save from "images/save.svg";
import ip15 from "images/test.svg";
import staff from "images/user.svg";

import i18n from "helpers/i18n";
import { ReactComponent as DecoratorBlob1 } from "images/exprience-deco1.svg";
import { ReactComponent as DecoratorBlob2 } from "images/exprience-deco2.svg";
import { ReactComponent as DecoratorBlob3 } from "images/exprience-deco3.svg";

import catalogEn from "images/catalogue-en.pdf";
import catalogVi from "images/catalogue-vi.pdf";
import videoUrl from "./video/remarketingrework.mp4";
import videoEnUrl from "./video/video_remarketing_en.mp4";
import live from "images/live-transformed.png";
import eyeLive from "images/eye_live.png";

import { useLayoutEffect, useRef } from "react";
import "./styles.css";

function Index({ heading }) {
  const videoRef = useRef(null);
  const playedRef = useRef(false);
  const playedMobileRef = useRef(false);

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (playedRef.current) return;
      const target = window.document
        .getElementById("videoWrapper")
        .getBoundingClientRect();
      if (target.y < 877) {
        playedRef.current = true;
        window.document.getElementById("video").play();
      }
    };

    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (playedMobileRef.current) return;
      const target = window.document
        .getElementById("videoMovileWrapper")
        .getBoundingClientRect();
      if (target.y < 639) {
        playedMobileRef.current = true;
        window.document.getElementById("videoMobile").play();
      }
    };

    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  const toggleCatalog = () => {
    if (i18n.language === "vi") {
      window.open(catalogVi, "_blank");
    } else {
      window.open(catalogEn, "_blank");
    }
  };

  let locationTxtGateful,
    locationTxtSave,
    locationTxtNews,
    locationTxtSells,
    locationTxtStaff;

  switch (i18n.language) {
    case "es":
      locationTxtGateful = "locationGateFulEs";
      locationTxtSave = "locationSaveEn";
      locationTxtNews = "locationNewsVi";
      locationTxtSells = "locationSellEs";
      locationTxtStaff = "locationStaffEs";
      break;
    case "id":
    case "vi":
      locationTxtGateful = "locationGatefulVi";
      locationTxtSave = "locationSaveVi";
      locationTxtNews = "locationNewsVi";
      locationTxtSells = "locationSellVi";
      locationTxtStaff = "locationStaffVi";
      break;
    case "en":
      locationTxtGateful = "locationGatefulVi";
      locationTxtSave = "locationSaveEn";
      locationTxtNews = "locationNewsEn";
      locationTxtSells = "locationSellVi";
      locationTxtStaff = "locationStaffEn";
      break;
    case "th":
      locationTxtGateful = "locationGatefulVi";
      locationTxtSave = "locationSaveEn";
      locationTxtNews = "locationNewsTh";
      locationTxtSells = "locationSellTh";
      locationTxtStaff = "locationStaffTh";
      break;
    case "pt":
      locationTxtGateful = "locationGatefulVi";
      locationTxtSave = "locationSaveEn";
      locationTxtNews = "locationNewsVi";
      locationTxtSells = "locationSellEs";
      locationTxtStaff = "locationStaffEs";
      break;
    default:
      break;
  }

  return (
    <div className="container mainLayout positionMain">
      <DecoratorBlob1 className="positionExtra decor1" />
      <DecoratorBlob2 className="positionExtra decor2" />
      <DecoratorBlob3 className="positionExtra decor3" />
      <h5 className="mainTitle">{i18n.t("User experience")}</h5>
      <h2 className="title">{heading}</h2>
      <div className="layout" id="videoWrapper">
        <div className="container mainLayout positionMain hidden-content">
          <div className="positionMain" style={{ zIndex: 2 }}>
            <Experience />
            <img
              src={live}
              width={400}
              style={{ position: "absolute", top: "38%", left: "72%" }}
            />
          </div>
          <video id="video" className="positionExtra video" muted loop>
            <source
              src={i18n.language === "vi" ? videoUrl : videoEnUrl}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div
            className={`positionExtra span txtGrateful spanGift ${locationTxtGateful}`}
          >
            <span>{i18n.t("Chương trình tri ân?")}</span>
          </div>
          <div
            className={`positionExtra span txtSave spanSave locationSaveVi ${locationTxtSave}`}
          >
            <span>{i18n.t("Tiết kiệm chi phí quảng cáo")}</span>
          </div>
          <div
            className={`positionExtra span txtNews spanNews locationNewsVi ${locationTxtNews}`}
          >
            <span>{i18n.t("Sản phẩm mới")}</span>
          </div>
          <div
            className={`positionExtra span spanSell locationSellVi ${locationTxtSells}`}
          >
            <span>{i18n.t("Bán ra nhiều hơn")}</span>
          </div>
          <div
            className={`positionExtra span txtStaff spanStaff ${locationTxtStaff}`}
          >
            <span>{i18n.t("Tối ưu nhân sự")}</span>
          </div>
          <div
            style={{
              position: "absolute",
              right: i18n.language !== "vi" ? -300 : -250,
              marginTop: 50,
            }}
          >
            <p
              style={{
                color: "#D9B342",
                fontSize: 24,
                fontWeight: 600,
              }}
            >
              {i18n.t("Thông báo phiên ")}
              <span style={{ color: "#EC371F" }}>{i18n.t("LIVE")}</span>
            </p>
          </div>
        </div>

        <div className="original-content container mainLayout positionMain">
          <div className="positionMain" id="videoMovileWrapper">
            <img
              src={ip15}
              className="positionMain"
              width="100%"
              alt="Video"
              style={{ zIndex: 2 }}
            />
            <video
              id="videoMobile"
              ref={videoRef}
              width="100%"
              style={{
                position: "absolute",
                top: 25,
                height: 665,
                borderRadius: 60,
              }}
              muted
              loop
            >
              <source
                src={i18n.language === "vi" ? videoUrl : videoEnUrl}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            <Line className="original-content positionExtra line" width={170} />
          </div>
          <div className="layoutContent">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <img src={bag} alt="Bag" />
              <span className="spanNews spacingIcon">
                {i18n.t("Sản phẩm mới?")}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <img src={staff} alt="Staff" />
              <span className="spanStaff spacingIcon">
                {i18n.t("Tối ưu nhân sự")}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <img src={money} alt="Money" />
              <span className="spanSell spacingIcon">
                {i18n.t("Bán ra nhiều hơn")}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <img src={gift} alt="Gift" />
              <span className="spanGift spacingIcon">
                {i18n.t("Chương trình tri ân?")}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <img src={save} alt="Save" />
              <span className="spanSave spacingIcon">
                {i18n.t("Tiết kiệm chi phí quảng cáo")}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <img src={eyeLive} alt="Live" width={25} />
              <p
                className="spacingIcon"
                style={{
                  color: "#D9B342",
                  fontSize: 20,
                }}
              >
                {i18n.t("Thông báo phiên ")}
                <span style={{ color: "#EC371F" }}>{i18n.t("LIVE")}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <p className="txtBottom">
        {i18n.t(
          "Khám phá thêm nhiều câu chuyện từ chính đối tác của chúng tôi."
        )}
      </p>
      <button className="btn" onClick={toggleCatalog}>
        <span className="txtButton">{i18n.t("Tải Catalogue")}</span>
        <ArrowExperience className="icon hidden-content" />
      </button>
    </div>
  );
}

export default Index;
