import { Container as ContainerBase } from "components/misc/Layouts.js";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import logo from "../../images/logo.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { ReactComponent as GooglePlayIcon } from "../../images/google-play.svg";

import i18n, { languages } from "helpers/i18n";
import enLang from "../../images/lang/circle_lang_en.png";
import viLang from "../../images/lang/circle_lang_vi.png";
import ptLang from "../../images/lang/circle_lang_pt.png";
import thLang from "../../images/lang/circle_lang_th.png";
import idLang from "../../images/lang/circle_lang_id.png";
import esLang from "../../images/lang/circle_lang_es.png";

const circle = {
  en: enLang,
  vi: viLang,
  pt: ptLang,
  th: thLang,
  id: idLang,
  es: esLang,
};

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const FlagContainer = tw.div`mt-8 font-medium gap-4 flex flex-wrap justify-center items-center flex-row`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;
export default () => {
  const scrollTo = (id) => {
    try {
      const el = document.getElementById(id);
      window.scrollTo({
        top: el
          ? el.offsetParent.offsetTop
            ? el.offsetParent.offsetTop
            : el.offsetTop
          : 0,
        behavior: "smooth",
      });
    } catch (e) {
      console.log(e);
    }
  };

  const changeLang = async (lang, locale) => {
    try {
      await i18n.changeLanguage(lang);
      localStorage.setItem("language", lang);
      localStorage.setItem("locale", locale);
      window.scrollTo({ top: 0 });
      window.location.reload();
    } catch (e) {
      console.log(lang, e);
    }
  };

  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
            <LogoText>Re:Marketing</LogoText>
          </LogoContainer>
          <FlagContainer>
            {languages.map((e) => (
              <img
                title={e.name}
                style={{ cursor: "pointer" }}
                onClick={() => changeLang(e.code, e.locale)}
                width={25}
                key={e.code}
                src={circle[e.code]}
                alt=""
              />
            ))}
          </FlagContainer>
          <LinksContainer>
            <Link href="#!" onClick={() => scrollTo(null)}>
              {i18n.t("Giới thiệu")}
            </Link>
            <Link href="#!" onClick={() => scrollTo("features")}>
              {i18n.t("Nội tại")}
            </Link>
            <Link href="#!" onClick={() => scrollTo("pricing")}>
              {i18n.t("Chi phí sử dụng")}
            </Link>
            <Link href="#!" onClick={() => scrollTo("faq")}>
              {i18n.t("Câu hỏi thường gặp")}
            </Link>
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink
              href={`https://chrome.google.com/webstore/detail/remarketing/enlgkmbkbkglicgibhffepnaniamjaej?hl=${i18n.language}`}
              target="_blank"
            >
              <GooglePlayIcon />
            </SocialLink>

            <SocialLink
              href="https://facebook.com/re.marketing.ext"
              target="_blank"
            >
              <FacebookIcon />
            </SocialLink>
            {/* <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink> */}
            <SocialLink
              href="https://www.youtube.com/@ReMarketingGlobal"
              target="_blank"
            >
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText style={{ lineHeight: "28px" }}>
            &copy; Copyright 2020, Vivoo Global Inc. All Rights Reserved.
            <br />
            * No. 04 Pham Ngu Lao, Phan Chu Trinh, Hoan Kiem, Ha Noi, Viet Nam
            <br />
            * No. 1840, Sukhumvit Hills Building, Sukhumvit Road, Bangkok,
            Thailand
            <br />
            <a
              href="tel:0389959613"
              target="_blank"
              rel="noreferrer"
              className="footerLink"
            >
              +84 389 959 613
            </a>
            {" | "}
            <a
              href="mailto:re-marketing.ext@gmail.com"
              target="_blank"
              rel="noreferrer"
              className="footerLink"
            >
              Email
            </a>
            {" | "}
            <a
              href="//zalo.me/0389959613"
              target="_blank"
              rel="noreferrer"
              className="footerLink"
            >
              Zalo
            </a>
            {" | "}
            <a
              href="//t.me/re.marketing.ext"
              target="_blank"
              rel="noreferrer"
              className="footerLink"
            >
              Telegram
            </a>
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
