import "slick-carousel/slick/slick.css";
import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import loveIllustrationImageSrc from "images/love-illustration.svg";
import { ReactComponent as StarIconBase } from "images/star-icon.svg";
import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg";
import partner0 from "../../images/re-marketing/p0.jpg";
import partner1 from "../../images/re-marketing/p1.jpg";
import partner2 from "../../images/re-marketing/p2.jpg";
import i18n from "helpers/i18n";

const Row = tw.div`flex flex-col md:flex-row justify-between items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 xl:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 xl:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:pr-12 lg:pr-16 md:order-first`
    : tw`md:pl-12 lg:pl-16 md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-6 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const TestimonialSlider = styled(Slider)`
    ${tw`w-full mt-10 text-center md:text-left`}
    .slick-track {
        ${tw`flex`}
    }
    .slick-slide {
        ${tw`h-auto flex justify-center mb-1`}
    }
`;

const Testimonial = tw.div`outline-none h-full flex! flex-col`;
const StarsContainer = styled.div``;
const StarIcon = tw(
  StarIconBase
)`inline-block w-5 h-5 text-orange-400 fill-current mr-1 last:mr-0`;
const TestimonialHeading = tw.div`mt-4 text-xl font-bold`;
const Quote = tw.blockquote`mt-4 mb-8 sm:mb-10 leading-relaxed font-medium text-gray-700`;

const CustomerInfoAndControlsContainer = tw.div`mt-auto flex justify-between items-center flex-col sm:flex-row`;

const CustomerInfo = tw.div`flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
const CustomerProfilePicture = tw.img`rounded-full w-16 h-16 sm:w-20 sm:h-20`;
const CustomerTextInfo = tw.div`text-center md:text-left sm:ml-6 mt-2 sm:mt-0`;
const CustomerName = tw.h5`font-bold text-xl`;
const CustomerTitle = tw.p`font-medium text-secondary-100`;

const Controls = styled.div`
    ${tw`flex mt-8 sm:mt-0`}
    .divider {
        ${tw`my-3 border-r`}
    }
`;
const ControlButton = styled.button`
    ${tw`mx-3 p-4 rounded-full transition duration-300 bg-gray-200 hover:bg-gray-300 text-primary-500 hover:text-primary-700 focus:outline-none focus:shadow-outline`}
    svg {
        ${tw`w-4 h-4 stroke-3`}
    }
`;

export default ({
  imageSrc = loveIllustrationImageSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  subheading = "Testimonials",
  heading = "Our Clients Love Us.",
  description = i18n.t("Xuất hiện từ 2020, là sản phẩm đi đầu, thật may mắn khi có những khách hàng gắn bó và giúp sản phẩm hoàn thiện từng ngày, chúng tôi tuyệt vời như ngày hôm nay là nhờ có các bạn."),
  textOnLeft = false,
  testimonials = [
    {
      stars: 5,
      profileImageSrc: partner2,
      heading: i18n.t("Trải nghiệm Tuyệt Vời"),
      quote: i18n.t("Trước khi sử dụng Re:Marketing tôi đã dùng một số phần mềm tương tự trên thị trường và có những trải nghiệm không vui vẻ gì trong cả việc sử dụng lẫn training nhân viên, và rồi tôi tìm thấy các bạn, phần mềm thực sự tuyệt vời. Ngay từ lần đầu trải nghiệm tôi đã biết đây là thứ mình cần, tiết kiệm được nguồn nhân sự trong công việc nhàm chán này."),
      customerName: "Nonglak Phetwong",
      customerTitle: "CMO, Shopify Thailand",
    },
    {
      stars: 5,
      profileImageSrc: partner0,
      heading: i18n.t("Tôi đã tăng doanh thu rất nhiều !"),
      quote: i18n.t("Tôi đang đau đầu trong việc tối ưu tệp khách hàng tiềm năng khổng lồ mà hàng tỷ đồng quảng cáo mang lại, thật đúng lúc, khi một người bạn đã giới thiệu cho tôi phần mềm này và đoán xem, thực sự bất ngờ khi chúng tôi đã kết nối lại với được gần 40% lượng khách hàng tiềm năng này, với những doanh nghiệp kiểu như tôi, tôi khuyên bạn đây là phần mềm phải có."),
      customerName: "Ha Van Thang",
      customerTitle: "Founder, Giao Hang Sieu Viet",
    },
    {
      stars: 5,
      profileImageSrc: partner1,
      heading: i18n.t("Yêu đội ngũ phát triển !"),
      quote: i18n.t("Tôi rất mới trong lĩnh vực kinh doanh trên Facebook, thời gian đầu quản lý nhân sự chốt đơn không hiệu quả mà chi phí quảng cáo ngày một tăng, khiến tệp khách hàng tiềm năng này mất dần đi, sau này, khi đạt được những kết quả ổn định hơn thì tôi rất cần một công cụ như các bạn, Re:Marketing đã giúp tôi giảm rất nhiều chi phí quảng cáo khi biến tệp khách hàng bỏ lỡ đó trở nên tiềm năng trở lại."),
      customerName: "João da Silva.",
      customerTitle: "CEO, Wix Inc.",
    },
  ],
}) => {
  const [sliderRef, setSliderRef] = useState(null);

  return (
    <Container>
      <ContentWithPaddingXl>
        <Row>
          <ImageColumn>
            <Image
              src={imageSrc}
              imageBorder={imageBorder}
              imageShadow={imageShadow}
              imageRounded={imageRounded}
            />
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <TestimonialSlider arrows={false} ref={setSliderRef}>
              {testimonials.map((testimonial, index) => (
                <Testimonial key={index}>
                  <StarsContainer>
                    {Array.from({
                      length: testimonial.stars,
                    }).map((_, indexIcon) => (
                      <StarIcon key={indexIcon} />
                    ))}
                  </StarsContainer>
                  <TestimonialHeading>
                    {testimonial.heading}
                  </TestimonialHeading>
                  <Quote>{testimonial.quote}</Quote>
                  <CustomerInfoAndControlsContainer>
                    <CustomerInfo>
                      <CustomerProfilePicture
                        src={
                          testimonial.profileImageSrc
                        }
                        alt={testimonial.customerName}
                      />
                      <CustomerTextInfo>
                        <CustomerName>
                          {testimonial.customerName}
                        </CustomerName>
                        <CustomerTitle>
                          {testimonial.customerTitle}
                        </CustomerTitle>
                      </CustomerTextInfo>
                    </CustomerInfo>
                    <Controls>
                      <ControlButton
                        onClick={sliderRef?.slickPrev}
                      >
                        <ArrowLeftIcon />
                      </ControlButton>
                      <div className="divider" />
                      <ControlButton
                        onClick={sliderRef?.slickNext}
                      >
                        <ArrowRightIcon />
                      </ControlButton>
                    </Controls>
                  </CustomerInfoAndControlsContainer>
                </Testimonial>
              ))}
            </TestimonialSlider>
          </TextColumn>
        </Row>
      </ContentWithPaddingXl>
    </Container>
  );
};
