import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from '../lang/en.json'
import th from '../lang/th.json'
import id from '../lang/id.json';
import es from '../lang/es.json';
import pt from '../lang/pt.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: en
    },
    th: {
        translation: th,
    },
    vi: {
    },
    id: {
        translation: id,
    },
    es: {
        translation: es,
    },
    pt: {
        translation: pt,
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: localStorage.getItem("language") ?? "vi", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export const languages = [
    {
        name: "English",
        code: "en",
        locale: "en_US"
    },
    {
        name: "ไทย",
        code: "th",
        locale: "th_TH"
    },
    {
        name: "Tiếng Việt",
        code: "vi",
        locale: "vi_VN"
    },
    {
        name: "Bahasa",
        code: "id",
        locale: "id_ID"
    },
    {
        name: "Spanish",
        code: "es",
        locale: "es_ES"
    },
    {
        name: "Português",
        code: "pt",
        locale: "pt_PT"
    },
];
export default i18n;
