import { PrimaryButton } from "components/misc/Buttons.js";
import { SectionHeading } from "components/misc/Headings.js";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import i18n from "helpers/i18n";
import { useState } from "react";
import tw from "twin.macro";

const Container = tw(ContainerBase)`bg-secondary-800 -mx-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;
const TextColumn = tw.div`flex items-center flex-col sm:flex-row`;
const FormColumn = tw.div`mt-12  w-full sm:w-auto`;

const HeadingInfoContainer = tw.div`sm:ml-6 mt-6 sm:mt-0`;
const Heading = tw(SectionHeading)`text-gray-100 sm:text-left leading-none`;
const Description = tw.p`text-gray-500 font-medium text-sm mt-2 sm:mt-1 text-center sm:text-left`;

const Form = tw.form`text-sm max-w-sm sm:max-w-none mx-auto`;
const Input = tw.input`capitalize w-full sm:w-auto block sm:inline-block px-6 py-4 rounded bg-secondary-600 tracking-wider font-bold border border-secondary-600 focus:border-secondary-300 focus:outline-none sm:rounded-r-none hover:bg-secondary-500 transition duration-300 text-gray-200`;
const Input1 = tw.input`lg:rounded-none w-full sm:w-auto block sm:inline-block px-6 py-4 rounded bg-secondary-600 tracking-wider font-bold border border-secondary-600 focus:border-secondary-300 focus:outline-none sm:rounded-r-none hover:bg-secondary-500 transition duration-300 text-gray-200`;
const Button = tw(
  PrimaryButton
)`w-full sm:w-auto mt-6 sm:mt-0 sm:rounded-l-none py-4 bg-green-500 text-gray-100 hocus:bg-green-700 hocus:text-gray-300 border border-green-500 hocus:border-green-700`;

export default () => {
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [isThanks, setIsThanks] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChangeName = (e) => {
    setName(e.target.value);
  };

  const handleChangePhone = (e) => {
    e.target.value = e.target.value.replace(/\D/, "");
    setPhone(e.target.value);
  };

  const interst = async (e) => {
    e.preventDefault();
    if (!phone.match(/(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}/)) {
      setErrMsg(i18n.t("Số điện thoại của bạn không hợp lệ."));
      return;
    }
    if (!name.trim()) {
      setErrMsg(i18n.t("Tên của bạn không hợp lệ."));
      return;
    }
    setErrMsg(false);
    setLoading(true);
    setIsThanks(false);
    try {
      const res = await fetch("https://api.re-marketing.click/guest/interest", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          phone,
        }),
      });
      const resj = await res.json();
      if (resj.success) {
        setIsThanks(true);
        setPhone("");
        setName("");
      } else {
        throw "";
      }
    } catch (e) {
      setErrMsg(i18n.t("Có lỗi gì đó."));
    }
    setLoading(false);
  };

  return (
    <Container>
      <Content>
        <Row>
          <TextColumn>
            <img
              src="https://www.google.com/chrome/static/images/chrome-logo-m100.svg"
              alt=""
            />
            <HeadingInfoContainer>
              <Heading>{i18n.t("Tin tưởng bởi Google")}</Heading>
              <Description>
                {i18n.t("Vui lòng để lại thông tin để nhận được tư vấn.")}
              </Description>
            </HeadingInfoContainer>
          </TextColumn>
          <fieldset disabled={loading}>
            <FormColumn>
              <Form onSubmit={interst}>
                <Input
                  name="name"
                  value={name}
                  onChange={handleChangeName}
                  type="text"
                  placeholder={i18n.t("Tên của bạn")}
                  required
                />
                <Input1
                  value={phone}
                  onChange={handleChangePhone}
                  name="phone"
                  type="text"
                  placeholder={i18n.t("Số điện thoại")}
                  maxLength={12}
                  required
                />
                <Button type="submit">{i18n.t("Quan tâm")}</Button>
              </Form>
            </FormColumn>
          </fieldset>
          {isThanks && (
            <div
              style={{
                marginTop: 16,
                color: "#47bb78",
              }}
            >
              {i18n.t(
                `Cảm ơn vì đã quan tâm, chúng tôi sẽ liên lạc với bạn sớm.`
              )}
            </div>
          )}
          {errMsg && (
            <div
              style={{
                marginTop: 16,
                color: "#cd4f4f",
              }}
            >
              {errMsg}
            </div>
          )}
        </Row>
      </Content>
    </Container>
  );
};
